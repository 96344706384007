import CustomTable from '@/common/components/custom/CustomTable';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { findPaymentReport } from '@/api/query/report';
import { getFormatedDate } from '@/common/functions/getFormatedDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getCountries } from '@/api/query/country';
import NewCustomTable from '@/common/components/custom/NewCustomTable';
import DataTable from '@/common/components/custom/DataTable';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  {
    header: 'no.',
    accessorKey: 'no',
    size: 20,
  },
  {
    header: 'Email',
    accessorKey: 'email',
    size: 20,
  },
  {
    header: 'Payment Email',
    accessorKey: 'paymentEmail',
    size: 20,
  },
  {
    header: 'Payment date',
    accessorKey: 'payment_date',
    size: 20,
  },
  {
    header: 'Type',
    accessorKey: 'type',
    size: 20,
  },
  {
    header: 'Price',
    accessorKey: 'price',
    size: 20,
  },
  {
    header: 'Country',
    accessorKey: 'country',
    size: 20,
  },
  {
    header: 'Currency',
    accessorKey: 'currency',
    size: 20,
  },
  {
    header: 'Ip Origin',
    accessorKey: 'ip_origin',
    size: 20,
  },
  {
    header: 'Page Origin',
    accessorKey: 'page_origin',
    size: 20,
  },
  {
    header: 'Payment Origin',
    accessorKey: 'payment_origin',
    size: 20,
  },
];

const CSV_HEADERS = [
  'No.',
  'Email',
  'Payment date',
  'Type',
  'Price',
  'Country',
  'Currency',
];

const PaymentReport = () => {
  const [tableData, setTableData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const findStatsMutation = useMutation({
    mutationFn: (values) => findPaymentReport(values),
    onSuccess: ({ data }) => {
      setTableData(() =>
        [...data.paid, ...data.returned].map((d, i) => ({
          no: i + 1,
          email: d.email,
          payment_date: d.paymentDate,
          type: d.type,
          price:
            d.type === 'COMPLETED'
              ? d.price.toString().replace('.', ',')
              : (d.price * -1).toString().replace('.', ','),
          country: d.country,
          ip_origin: d.ipOrigin,
          page_origin: d.pageOrigin,
          payment_origin: d.paymentOrigin,
          currency: d.currency,
          paymentEmail: d.paymentEmail || '----',
        })),
      );

      setCsvData(() =>
        [...data.paid, ...data.returned].map((d, i) => [
          i + 1,
          d.paymentEmail || d.email,
          d.paymentDate,
          d.type,
          d.type === 'COMPLETED'
            ? d.price.toString().replace('.', ',')
            : (d.price * -1).toString().replace('.', ','),
          d.country,
          d.currency,
        ]),
      );
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };
  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar
            handleSearch={handleSearch}
            csvData={csvData}
            headers={CSV_HEADERS}
          />
        </TableWrapper>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default PaymentReport;
